// extracted by mini-css-extract-plugin
export var article = "c_v";
export var articleBody = "c_x";
export var articleDesc = "c_D";
export var articleImg = "c_y";
export var articleMeta = "c_z";
export var articleMetaCategory = "c_C";
export var articleMetaType = "c_B";
export var articleTitle = "c_w";
export var grid = "c_t";
export var header = "c_d";
export var main = "c_b";
export var thumb = "c_s";
export var ttl = "c_r";