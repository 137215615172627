import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image';
import classnames from 'classnames';

import Layout from '../components/layout/layout';
import RichText from '../components/rich-text/rich-text';
import Seo from '../components/seo/seo';

import Txt from '../images/txt.inline.svg';
import Mic from '../images/mic.inline.svg';
import Vid from '../images/vid.inline.svg';

import {
  header,
  ttl,
  thumb,
  main,
  grid,
  article,
  articleImg,
  articleBody,
  articleMeta,
  articleMetaType,
  articleMetaCategory,
  articleTitle,
  articleDesc,
} from '../scss/pages/knowledge.module.scss';

const Knowledge = () => {
  const data = useStaticQuery(graphql`
    {
      wp {
        page(id: 169, idType: DATABASE_ID) {
          title
          seo {
            title
            canonical
            cornerstone
            focuskw
            metaDesc
            metaKeywords
            metaRobotsNofollow
            metaRobotsNoindex
            opengraphAuthor
            opengraphDescription
            opengraphImage {
              sourceUrl
              mediaDetails {
                width
                height
              }
              mimeType
            }
            opengraphModifiedTime
            opengraphPublishedTime
            opengraphPublisher
            opengraphSiteName
            opengraphTitle
            opengraphType
            opengraphUrl
            readingTime
            twitterDescription
            twitterImage {
              sourceUrl
              mediaDetails {
                width
                height
              }
              mimeType
            }
            twitterTitle
            schema {
              articleType
              pageType
              raw
            }
          }
        }
        posts {
          nodes {
            slug
            title
            categories {
              nodes {
                name
                slug
                id
              }
            }
            data {
              postType
              description
              image {
                sourceUrl
                altText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(width: 720, height: 440)
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const { title, seo } = data.wp.page;
  const posts = data.wp.posts.nodes;

  return (
    <>
      <Seo {...seo} />
      <Layout landingNav={false}>
        <div className={header}>
          <div className={'container'}>
            <h1 className={ttl}>{title}</h1>
          </div>
          <StaticImage
            src={'../images/knowledge.jpg'}
            alt={'table'}
            className={thumb}
          />
        </div>
        <div className={main}>
          <div className={classnames('container', grid)}>
            {posts.map((post, idx) => {
              const thumb = post.data.image;
              return (
                <div key={idx} className={article}>
                  <Link to={post.slug} className={articleImg}>
                    <GatsbyImage
                      alt={thumb.altText}
                      image={thumb.imageFile.childImageSharp.gatsbyImageData}
                    />
                  </Link>
                  <div className={articleBody}>
                    <div className={articleMeta}>
                      <ul className={articleMetaType}>
                        {post.data.postType.map((postType) => {
                          return (
                            <li key={postType}>
                              {postType === 'text' && <Txt />}
                              {postType === 'video' && <Vid />}
                              {postType === 'podcast' && <Mic />}
                            </li>
                          );
                        })}
                      </ul>
                      <ul className={articleMetaCategory}>
                        {post.categories?.nodes?.map((category) => {
                          return <li key={category.slug}>{category.name}</li>;
                        })}
                      </ul>
                    </div>
                    <h2 className={articleTitle}>
                      <Link to={post.slug}>{post.title}</Link>
                    </h2>
                    <RichText className={articleDesc}>
                      {post.data.description}
                    </RichText>
                    {idx === 0 && (
                      <div>
                        <Link
                          to={post.slug}
                          className={'button secondary hollow'}
                        >
                          Read more
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Knowledge;
